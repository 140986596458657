import React, { useEffect, useState } from "react"
import PhoneInput from "react-phone-input-2"
import { FormContext } from "../Context"
import { Box, Text } from "@chakra-ui/react"
import "react-phone-input-2/lib/style.css"

function Phone({ id }) {
  const { handleChange, answers, setPhoneValid } = React.useContext(FormContext)
  const field = answers[id[1]]
  const [country, setCountry] = React.useState("United Arab Emirates")
  const [hasEdited, setHasEdited] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  function checkIsPhoneValid(phone) {
    const mobileValidPattern =
      /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm

    return mobileValidPattern.test(phone) && isInNumberRange(country, phone)
  }

  function isInNumberRange(country, phone) {
    switch (country) {
      case "United States":
        return Boolean(phone.length > 10 && phone.length < 12)
      case "India":
        return Boolean(phone.length > 9 && phone.length < 13)
      case "Pakistan":
        return Boolean(phone.length > 9 && phone.length < 13)
      case "United Arab Emirates":
        return Boolean(phone.length <= 13 && phone.length >= 9)
      default:
        return Boolean(phone.length >= 6)
    }
  }

  useEffect(() => {
    const countryCode = answers[id[0]].value
    const justPhoneDigits = field.value.replace(countryCode, "")
    const valid = checkIsPhoneValid(justPhoneDigits, country)

    setPhoneValid(valid)
    if (hasEdited) {
      setShowErrorMessage(!valid)
    }
  }, [answers, hasEdited, country])

  return (
    <Box>
      <PhoneInput
        enableAreaCodes={false}
        enableSearch
        disableSearchIcon
        countryCodeEditable={false}
        country="ae"
        value={field.value}
        onBlur={() => {
          setHasEdited(true)
        }}
        onMount={(_, countryCode) => {
          handleChange(`+${countryCode.dialCode}`, id[0])
        }}
        onChange={(phone, countryCode) => {
          handleChange(`+${countryCode.dialCode}`, id[0])
          handleChange(`+${phone}`, id[1])
          setCountry(countryCode.name)
        }}
        inputProps={{
          name: answers[id[1]].name,
          id: answers[id[1]].name,
          required: true,
          autoFocus: false,
        }}
        containerClass="phone-input"
        isValid={value => {
          const pattern =
            /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm
          return pattern.test(value)
        }}
      />
      {showErrorMessage && (
        <Text color="red.500" fontSize="13px!important" pt={1}>
          Please enter a phone number for {country}
        </Text>
      )}
    </Box>
  )
}

export default Phone
