import React from "react"
import { Input } from "@chakra-ui/react"
import { FormContext } from "../Context"

function FirstName({ id }) {
  const { answers } = React.useContext(FormContext)
  const { handleChange } = React.useContext(FormContext)
  const field = answers[id]

  return (
    <Input
      placeholder={field.placeholder || "Enter your first name"}
      gridColumnStart={1}
      gridColumnEnd={[3, 3, 2]}
      size="lg"
      fontSize={["xs", "sm", "md"]}
      bg="dark.50"
      value={field.value}
      id={field.name}
      name={field.name}
      onChange={e => {
        if (e.target.value.length <= 40) handleChange(e.target.value, id)
      }}
      isRequired={field.required}
      _placeholder={{ color: "gray.400" }}
    />
  )
}

export default FirstName
