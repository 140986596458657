import React from "react"
import { Input } from "@chakra-ui/react"
import { FormContext } from "../Context"

function LastName({ id }) {
  const { handleChange, answers } = React.useContext(FormContext)
  const field = answers[id]

  return (
    <Input
      placeholder={field.placeholder || "Enter your last name"}
      gridColumnStart={[1, 1, 2]}
      gridColumnEnd={[3, 3, 3]}
      size="lg"
      bg="dark.50"
      fontSize={["xs", "sm", "md"]}
      value={field.value}
      id={field.name}
      name={field.name}
      isRequired={field.required}
      onChange={e => {
        if (e.target.value.length <= 80) handleChange(e.target.value, id)
      }}
    />
  )
}

export default LastName
