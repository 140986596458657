import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "wildComponents/Layout"
import { Seo } from "wildComponents/seo/components"
import Context from "../components/forms/cost-calculator/Context"
import Form from "../components/forms/cost-calculator/Form"
import Sidebar from "../components/forms/cost-calculator/Sidebar"
import Columns from "../components/forms/cost-calculator/ui/Columns"
import Section from "../components/forms/cost-calculator/ui/Section"
import Cost from "../components/forms/cost-calculator/Cost"

export const Head = ({ location, data, pageContext }) => (
  <Seo ctx={pageContext} data={data} location={location} />
)

function CostCalculator(props) {
  return (
    <Layout withTopBar={false}>
      <Context>
        <Section>
          <Columns>
            <Form />
            <Sidebar />
            <Cost />
          </Columns>
        </Section>
      </Context>
    </Layout>
  )
}

export default CostCalculator

export const pageQuery = graphql`
  query {
    wpPage(isPostsPage: { eq: true }) {
      title
    }
  }
`
