import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { FormContext } from "../Context"
import { useRef } from "react"

function Email({ id }) {
  const { answers, setEmailValid, emailValid } = React.useContext(FormContext)
  const { handleChange } = React.useContext(FormContext)
  const field = answers[id]
  const inputRef = useRef()

  const validEmailTest =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
      field.value
    )

  useEffect(() => {
    setEmailValid(validEmailTest)
  }, [answers])

  return (
    <FormControl
      gridColumnStart={1}
      gridColumnEnd={[3, 3, 2]}
      isInvalid={!emailValid}
    >
      <Input
        placeholder={field.placeholder || "Email Address"}
        ref={inputRef}
        size="lg"
        bg="dark.50"
        fontSize={["xs", "sm", "md"]}
        id={field.name}
        name={field.name}
        value={field.value}
        border={"none"}
        onChange={e => {
          handleChange(e.target.value, id)
        }}
        isRequired={field.required}
      />

      <FormErrorMessage>Please include a valid email.</FormErrorMessage>
    </FormControl>
  )
}

export default Email
