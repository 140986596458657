import React from "react"
import { Select } from "@chakra-ui/react"
import { FormContext } from "../Context"

function Nationality({ id }) {
  const { handleChange, answers } = React.useContext(FormContext)
  const field = answers[id]

  return (
    <Select
      bg="dark.50"
      placeholder={field.placeholder || "Nationality"}
      fontSize={["xs", "sm", "md"]}
      color={field.value === "" ? "gray.400" : "inherit"}
      gridColumnStart={[1]}
      gridColumnEnd={[3, 3, 2]}
      value={field.value}
      size="lg"
      name={field.name}
      id={field.name}
      onChange={e => {
        handleChange(e.target.value, id)
      }}
    >
      {field.options.map(option => {
        return (
          <option key={option.value} value={option.value}>
            {option.value}
          </option>
        )
      })}
    </Select>
  )
}

export default Nationality
